@font-face {
    font-family: 'Bull-Bold';
    src: url('../utils/fonts/bull/Bull-Bold.woff2') format('woff2'), url('../utils/fonts/bull/Bull-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

.registration-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* Styling specific to the success message */
.registration-success {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
    height: 300px;
    text-align: center; /* Ensures that the text is centred */
}

.logo-placeholder {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styling for the success message and the link */
.success-message {
    color: #000f1e;
    font-size: 16px;
}
.success-message strong {
    display: block; /* Makes it a block element */
    margin-bottom: 10px; /* Adds a lower distance */
}

.bold-text {
    font-family: 'Bull-Bold', sans-serif;
}

.button {
    background-color: #001c39;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'BullText-Medium', sans-serif;
    font-size: 14px; /* Sets the font size */
    font-weight: normal; /* Ensures that the text is not bold */
}
