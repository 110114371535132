.registration-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.registration-form input {
    padding: 8px;
    margin: 0; /* Adds a bottom distanceMakes sure that there is no additional outside distance */
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.registration-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px; /* Sets the spacing between the elements to 16px */
    width: 300px;
}

.registration-form-logo-placeholder {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 20px;
}

input:first-of-type {
    margin-top: 0px; /* Increases the distance to the top edge or to the previous element */
}

input,
select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Light grey as standard*/
select.not-selected {
    color: #757575; /* Light grey */
}

/* Black if an element has been selected */
select.selected {
    color: black;
}

/*  Grey for the options to ensure consistency */
select option {
    color: black;
}

.form-error {
    color: #f50b48; /* Redbull Red colour code */
    margin-top: 10px;
    text-align: center;
}
.options-container {
    position: absolute;
    z-index: 1000;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: white;
}

.dropdown-container {
    position: relative; /* New reference point for absolute positioning */
}

.options-container {
    position: absolute;
    z-index: 1000;
    width: 100%; /* Width according to the input field */
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: white;
    top: 100%; /* Positioned directly below the input field */
    left: 0;
}

.dropdown-option {
    padding: 10px;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: #f2f2f2;
}

.input-error,
.select-error__control {
    border: 1px solid #f50b48 !important;
}

.select-error__control {
    border-color: #f50b48 !important;
}

.input-error,
.select-error__control {
    border: 1px solid #f50b48 !important;
}

.select-error__control {
    border-color: #f50b48 !important;
}

.registration-form input,
.registration-form select,
.registration-form button,
.registration-form textarea {
    color: #000f1e;
    font-family: 'BullText-Medium', sans-serif;
    font-size: 14px; /* Sets the font size */
    font-weight: normal; /* Ensures that the text is not bold */
}

/* Sets the placeholder colour */
.registration-form input::placeholder,
.registration-form textarea::placeholder {
    color: rgba(0, 15, 30, 0.4);
}

.registration-form .button {
    background-color: #001c39;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-footer {
    text-align: center;
    margin-top: 20px;
}

.account-hint {
    margin-top: 10px; /* Reduced value */
    text-align: center;
}

.account-text-container {
    position: relative;
    padding-top: 20px; /* Ensures that there is enough space for the "Sign-Up" text */
}

.signup-text {
    top: 10px; /* Positions the text 10px above the top edge of the container */
    left: 0;
    margin: 0; /* Removes any existing standard margins */
    font-size: 18px;
    text-align: center;
}
