@font-face {
    font-family: 'BullText-Medium';
    src: url('./utils/fonts/bulltext/BullText-Medium.woff2') format('woff2'),
        url('./utils/fonts/bulltext/BullText-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    background-image: url('./utils/images/background.jpg'); /* Path to the background image */
    background-size: cover; /* Background image is proportionally scaled to cover the entire element */
    background-repeat: no-repeat; /* Background image does not repeat */
    /*background-position: center bottom;*/ /* For Background_old setting */
    background-position: right center;
    background-attachment: fixed; /* Background image stays fixed when scrolling */
    font-family: 'BullText-Medium', sans-serif;
}

.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
    width: 100vw; /* 100% of the viewport width */
    background-color: #f0f0f0; /* Background color of your choice */
}

a {
    color: #175aca; /* Color of the links */
    text-decoration: none; /* Optional: removes the underline */
}

a:hover {
    text-decoration: underline; /* Optional: adds an underline on hover */
}
